import React from 'react';
import { Colors } from '../../theme/Colors';

interface TrashIcon2Props {
  className?: string;
}

const TrashIcon2: React.FC<TrashIcon2Props> = ({ className }) => {
  return (
    <svg
      className={className}
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M7.5 1.5C6.80962 1.5 6.25 2.05964 6.25 2.75V3H9.25V2.75C9.25 2.05964 8.69038 1.5 8 1.5H7.5ZM10.75 3V2.75C10.75 1.23122 9.51882 0 8 0H7.5C5.98118 0 4.75 1.23122 4.75 2.75V3H2.5009H0.75C0.335786 3 0 3.33579 0 3.75C0 4.16421 0.335786 4.5 0.75 4.5H1.81243L2.59401 13.4883C2.71759 14.9094 3.90725 16 5.33367 16H10.1664C11.5928 16 12.7825 14.9094 12.906 13.4883L12.906 13.4883L13.6877 4.5H14.75C15.1642 4.5 15.5 4.16421 15.5 3.75C15.5 3.33579 15.1642 3 14.75 3H12.9992H10.75ZM12.182 4.5H3.31809L4.08838 13.3584C4.14454 14.0043 4.68528 14.5 5.33367 14.5H10.1664C10.8148 14.5 11.3555 14.0043 11.4117 13.3584L11.4117 13.3584L12.182 4.5Z'
        fill={Colors.B40}
      />
    </svg>
  );
};

export default TrashIcon2;
