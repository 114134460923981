import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import { StringKeyObject, Theme } from '../types';
import logger from './logger';

export const parseXLSXOrCSVThemesFileFactory =
  (onComplete: (results: Theme[]) => void, onError: () => void) =>
  async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (!file) {
      logger.info("couldn't find file");
      return;
    }

    if (file.type === 'text/csv') {
      Papa.parse(file, {
        transformHeader(header) {
          return header.toLowerCase();
        },
        header: true,
        complete: async function (results) {
          const localThemes = results.data as Theme[];
          const isValid = localThemes.every((res) =>
            Object.keys(res).includes('name')
          );
          if (!isValid) {
            onError();
            return;
          }

          onComplete(localThemes);
        },
      });
    } else if (
      file.type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      file.type === 'application/vnd.ms-excel'
    ) {
      const fileBuffer = await file.arrayBuffer();

      const workbook = XLSX.read(fileBuffer);
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      // require only name column; optional code_id, instructions

      if (!worksheet.A1) {
        onError();
        return;
      }

      worksheet.A1.w = worksheet.A1.w.toLowerCase();
      if (worksheet.B1) {
        worksheet.B1.w = worksheet.B1.w.toLowerCase();
      }
      if (worksheet.C1) {
        worksheet.C1.w = worksheet.C1.w.toLowerCase();
      }
      if (worksheet.D1) {
        worksheet.D1.w = worksheet.D1.w.toLowerCase();
      }
      const raw_data = XLSX.utils.sheet_to_json(worksheet);
      const localThemes = raw_data as Theme[];

      onComplete(localThemes);
    } else {
      onError();
    }
  };

export const hasColumnWithContentsAndEmptyHeader = (
  results: Papa.ParseResult<StringKeyObject>
): boolean => {
  const emptyColumnHeaderWithContent = results.meta.fields?.filter(
    (m) => !m.trim() && results.data.some((d) => !!d[m]?.trim())
  );

  logger.info(
    `hasColumnWithContentsAndEmptyHeader, emptyColumnHeaderWithContent: ${emptyColumnHeaderWithContent}`
  );

  return !!emptyColumnHeaderWithContent?.length;
};

export const hasEmptyColumnsWithEmptyHeader = (
  results: Papa.ParseResult<StringKeyObject>
) => {
  const emptyColumnFields = results.meta.fields?.filter(
    (m) => !m.trim() && results.data.every((d) => !d[m]?.trim())
  );

  logger.info(
    `hasEmptyColumnsWithEmptyHeader, emptyColumnFields : ${emptyColumnFields}`
  );

  return !!emptyColumnFields?.length;
};

export const cleanEmptyColumns = (
  results: Papa.ParseResult<StringKeyObject>
): Papa.ParseResult<StringKeyObject> => {
  const filteredResults = { ...results };
  filteredResults.meta.fields = results.meta.fields?.filter(
    (m) => !!m.trim() || results.data.some((d) => !!d[m]?.trim())
  );
  filteredResults.data = results.data.map((d) => {
    const temp = { ...d };
    delete temp[''];
    return temp;
  });

  logger.info(
    `cleaning empty columns, results.meta.fields: ${results.meta.fields}, filteredResults.meta.fields: ${filteredResults?.meta?.fields}`
  );

  return filteredResults;
};
