import React from 'react';

interface FileIconProps {
  className?: string;
}

const UploadedFileIcon: React.FC<FileIconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width='54'
      height='57'
      viewBox='0 0 54 57'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_4148_29012)'>
        <rect
          x='6.81445'
          y='7.0116'
          width='31.2558'
          height='41.6744'
          rx='4'
          fill='#E1DDF4'
        />
        <rect
          x='25.0469'
          y='27.8496'
          width='28.6512'
          height='28.6512'
          rx='14.3256'
          fill='#E1DDF4'
        />
        <path
          d='M36.9542 23.0493V13.1915C36.9542 12.9093 36.8239 12.6486 36.6395 12.4423L25.5874 0.836611C25.3811 0.619622 25.0878 0.5 24.7949 0.5H7.27208C4.03712 0.5 1.45312 3.13804 1.45312 6.3734V39.5079C1.45312 42.7432 4.03712 45.338 7.27208 45.338H21.1037C23.7199 49.6805 28.4754 52.59 33.8925 52.59C42.1218 52.59 48.8421 45.9023 48.8421 37.6623C48.8532 30.4643 43.6852 24.4498 36.9542 23.0493ZM25.8806 4.31081L33.2956 12.1164H28.4861C27.053 12.1164 25.8806 10.9333 25.8806 9.50026V4.31081ZM7.27208 43.1665C5.24208 43.1665 3.6246 41.5379 3.6246 39.5079V6.3734C3.6246 4.33227 5.24208 2.67148 7.27208 2.67148H23.7092V9.50026C23.7092 12.1383 25.8481 14.2879 28.4861 14.2879H34.7827V22.7668C34.4573 22.756 34.1966 22.7235 33.9144 22.7235C30.1254 22.7235 26.6405 24.1784 24.0132 26.4584H10.2253C9.62795 26.4584 9.13953 26.9468 9.13953 27.5437C9.13953 28.141 9.62795 28.6294 10.2253 28.6294H21.9939C21.2229 29.7152 20.5823 30.8009 20.0832 31.9952H10.2253C9.62795 31.9952 9.13953 32.4836 9.13953 33.0809C9.13953 33.6778 9.62795 34.1666 10.2253 34.1666H19.3773C19.1059 35.2524 18.9648 36.4573 18.9648 37.6623C18.9648 39.6164 19.3448 41.549 20.0287 43.1776H7.27208V43.1665ZM33.9037 50.4296C26.8686 50.4296 21.147 44.7081 21.147 37.673C21.147 30.638 26.8575 24.9164 33.9037 24.9164C40.9494 24.9164 46.6599 30.638 46.6599 37.673C46.6599 44.7081 40.9387 50.4296 33.9037 50.4296Z'
          fill='#8979D1'
        />
        <path
          d='M10.2253 23.1904H21.2229C21.8203 23.1904 22.3087 22.7016 22.3087 22.1047C22.3087 21.5074 21.8203 21.0189 21.2229 21.0189H10.2253C9.62796 21.0189 9.13953 21.5074 9.13953 22.1047C9.13953 22.7016 9.62796 23.1904 10.2253 23.1904Z'
          fill='#8979D1'
        />
        <path
          d='M39.8096 32.7876L31.6018 40.3003L27.5852 35.9359C27.1834 35.4908 26.4883 35.4694 26.0543 35.8707C25.6092 36.2725 25.5874 36.9676 25.9891 37.4016L30.7442 42.5584C30.9612 42.7866 31.2545 42.9062 31.5478 42.9062C31.8081 42.9062 32.0688 42.8084 32.2858 42.6236L41.286 34.3729C41.7311 33.9711 41.7637 33.276 41.3512 32.842C40.9387 32.4077 40.2548 32.3751 39.8096 32.7876Z'
          fill='#8979D1'
        />
      </g>
      <defs>
        <clipPath id='clip0_4148_29012'>
          <rect
            width='53.3954'
            height='56'
            fill='white'
            transform='translate(0.302734 0.5)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UploadedFileIcon;
