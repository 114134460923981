import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import styled from 'styled-components';
import {
  resetProjectApi,
  uploadDataAndSetupProjectApi,
} from '../../api/pipelineApi';
import { Colors } from '../../theme/Colors';
import { GENERAL_ERROR_MESSAGE } from '../../utils/constants';
import logger from '../../utils/logger';
import ButtonWithIcon from '../buttons/ButtonWithIcon';
import PrimaryButton from '../buttons/PrimaryButton';
import CenteredVerticalFlex from '../containers/CenteredVerticalFlex';
import VerticalFlex from '../containers/VerticalFlex';
import DropzoneContainer from '../file-uploader/DropzoneContainer';
import UploadDataFileInputWithDropzone from '../file-uploader/UploadDataFileInputWithDropzone';
import TrashIcon2 from '../icons/TrashIcon2';
import UploadedFileIcon from '../icons/UploadedFileIcon';
import ErrorModal from '../modal/ErrorModal';
import P1 from '../newTextComponents/P1';
import P2 from '../newTextComponents/P2';
import LoadingModal from '../tagResults/LoadingModal';

interface UploadDataFileProps {
  projectId: string;
  fileName?: string;
  isFileUploaded: boolean;
  onDataFileProcessComplete: () => void;
  onClickNext: VoidFunction;
}

const UploadDataFile: React.FC<UploadDataFileProps> = ({
  projectId,
  fileName,
  isFileUploaded,
  onDataFileProcessComplete,
  onClickNext,
}) => {
  const queryClient = useQueryClient();
  const [dataFileErrorMessage, setDataFileErrorMessage] = useState('');

  const {
    mutateAsync: uploadDataAndSetupProjectMutateAsync,
    isPending: uploadDataAndSetupProjectIsLoading,
    error: uploadDataAndSetupProjectError,
    reset: uploadDataAndSetupProjectReset,
  } = useMutation<any, Error, FormData>({
    mutationFn: (data) => uploadDataAndSetupProjectApi(data, projectId!),
    retry: 4,
  });

  const {
    mutateAsync: resetProjectMutateAsync,
    isPending: resetProjectIsLoading,
    error: resetProjectError,
    reset: resetProjectReset,
  } = useMutation<any, Error>({
    mutationFn: () => resetProjectApi(projectId!),
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['project', projectId] });
      queryClient.invalidateQueries({ queryKey: ['columnDefinitions'] });
    },
  });

  const onlyUploadFile = async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (!file) {
      logger.info("couldn't find file");
      return;
    }

    if (
      file.type !== 'text/csv' &&
      file.type !==
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' &&
      file.type !== 'application/vnd.ms-excel' &&
      !file.name.toLowerCase().endsWith('.sav')
    ) {
      setDataFileErrorMessage(
        'Unsupported file type, please upload a CSV, XLSX, or SPSS file'
      );
      return;
    }

    const formData = new FormData();
    formData.set('data_file', file);
    formData.set('file_name', file.name);
    await uploadDataAndSetupProjectMutateAsync(formData);
    onDataFileProcessComplete();
  };

  const removeFile = async () => {
    await resetProjectMutateAsync();
  };

  return (
    <Container>
      {isFileUploaded ? (
        <VerticalFlex>
          <DropzoneContainer $withHover={false}>
            <FileImageContainer>
              <UploadedFileIcon />
              <FileName>{fileName}</FileName>
              <RemoveText onClick={removeFile}>Remove</RemoveText>
            </FileImageContainer>
            <RemoveButton icon={<TrashIcon2 />} onClick={removeFile} />
          </DropzoneContainer>
          <NextButton label='Next' onClick={onClickNext} />
        </VerticalFlex>
      ) : (
        <UploadDataFileInputWithDropzone onSelectFile={onlyUploadFile} />
      )}
      <LoadingModal
        title='Removing old data...'
        hidden={!resetProjectIsLoading}
      />
      <ErrorModal
        show={!!resetProjectError}
        onClose={resetProjectReset}
        title={'Remove Old Data File Error'}
        subTitle={resetProjectError?.message || GENERAL_ERROR_MESSAGE}
      />
      <LoadingModal
        title='Uploading data file...'
        hidden={!uploadDataAndSetupProjectIsLoading}
      />
      <ErrorModal
        show={!!uploadDataAndSetupProjectError}
        onClose={uploadDataAndSetupProjectReset}
        title={'Upload Data File Error'}
        subTitle={
          uploadDataAndSetupProjectError?.message || GENERAL_ERROR_MESSAGE
        }
      />
      <ErrorModal
        show={!!dataFileErrorMessage}
        onClose={() => setDataFileErrorMessage('')}
        title={'Data File Format Error'}
        subTitle={dataFileErrorMessage!}
      />
    </Container>
  );
};

export default UploadDataFile;

const Container = styled(VerticalFlex)``;

const FileImageContainer = styled(CenteredVerticalFlex)`
  position: relative;
`;

const NextButton = styled(PrimaryButton)`
  width: 120px;
  align-self: flex-end;
  margin-top: 16px;
`;

const FileName = styled(P1)`
  margin-top: 24px;
`;

const RemoveText = styled(P2)`
  font-size: 13px;
  color: ${Colors.B60};
  cursor: pointer;
  padding: 8px;
`;

const RemoveButton = styled(ButtonWithIcon)`
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 20px;
`;
